<div
	class="search-filter"
	[ngClass]="searchStatus ? 'search-filter-collaps' : ''"
>
	<div class="search-heading">
		<h2>Search Filters</h2>
		<button class="btn-collaps" (click)="searchToggel()" aria-label="sidebar navigation" aria-expanded="false">
			<i class="fas fa-chevron-left"></i>
		</button>
	</div>
	<ng-container *ngIf="loaded">
		<form [formGroup]="searchform">
			<div class="search-control">
				<div class="exploring-div">
					<fieldset>
						<legend>Exploring</legend>
						<app-checkbox-group
						[formGroup]="searchform"
						[data]="explorings"
						name="exploringArray"
						id="exploring"
						[noPadding]="true"
						[educatorMode]="educatorMode"
						[checkeds]="true"
					></app-checkbox-group>
					<div class="d-none" aria-live="polite">
						{{ exploringInfo }}
					</div>
					</fieldset>
				</div>

				<div class="createdby-div">
					<fieldset>
						<legend>Created By</legend>
						<div class="custom-control custom-checkbox">
							<input
								type="checkbox"
								class="custom-control-input"
								id="all-createby"
								(change)="selectAllCreateby($event)"
								name="example1"
								checked='true'
							/>
							<label class="custom-control-label" for="all-createby"
								>All Creators</label
							>
						</div>
						<app-checkbox-group
							[formGroup]="searchform"
							[data]="createdBys"
							name="checkArray"
							id="created-by"
							[educatorMode]="educatorMode"
							[checkeds]="checkCreateby"
						></app-checkbox-group>
					</fieldset>
				</div>

				<app-grade-range [searchform]="searchform"></app-grade-range>

				<app-topics [parent]="searchform"></app-topics>

				<app-most-popular [formGroup]="searchform"></app-most-popular>

				<div class="audinece-div">
					<fieldset>
						<legend>Audience Type</legend>
						<div class="custom-control custom-checkbox selected">
							<input
								type="checkbox"
								class="custom-control-input"
								name="all-audience"
								id="all-audience"
								(change)="selectAllAudience($event)"
								checked='true'
							/>
							<label class="custom-control-label" for="all-audience">All</label>
						</div>
					</fieldset>

					<fieldset>
						<legend>Formal</legend>
						<app-checkbox-group
						[formGroup]="searchform"
						[data]="formalAudienceTypes"
						name="audienceTypeArray"
						id="formal"
						[educatorMode]="educatorMode"
						[checkeds]="checkAudience"
					></app-checkbox-group>
					</fieldset>

					<fieldset>
						<legend>Informal</legend>
						<app-checkbox-group
						[formGroup]="searchform"
						[data]="inFormalAudienceTypes"
						name="audienceTypeArray"
						id="informal"
						[educatorMode]="educatorMode"
						[checkeds]="checkAudience"
					></app-checkbox-group>
					</fieldset>


				</div>

				<div class="language-div">
					<fieldset>
						<legend>Languages</legend>
						<div class="custom-control custom-checkbox selected">
							<input
								type="checkbox"
								class="custom-control-input"
								id="all-languages"
								name="all-languages"
								(change)="selectAllLanguage($event)"
								checked='true'
							/>
							<label class="custom-control-label" for="all-languages">All</label>
						</div>
					</fieldset>

					<fieldset>
						<legend>Supported</legend>
						<app-checkbox-group
						[formGroup]="searchform"
						[data]="languages"
						name="languageArray"
						id="language"
						[educatorMode]="true"
						[checkeds]="checkLanguage"
					></app-checkbox-group>
					</fieldset>

				</div>


				<div class="campatiblity-div">
					<fieldset>
						<legend>Hardware Comapatibility</legend>
						<div class="custom-control custom-checkbox selected">
							<input
								type="checkbox"
								class="custom-control-input"
								id="all-hardwares"
								name="all-hardwares"
								(change)="selectAllHardware($event)"
								checked='true'
							/>
							<label class="custom-control-label" for="all-hardwares">All</label>
						</div>
					</fieldset>

					<fieldset>
						<legend>Hardware</legend>
						<app-checkbox-group
						[formGroup]="searchform"
						[data]="browsers"
						name="hardwareArray"
						id="browser-hardware"
						[educatorMode]="true"
						[checkeds]="checkHardware"
					></app-checkbox-group>
					</fieldset>

					<fieldset>
						<legend>Browser</legend>
						<app-checkbox-group
						[formGroup]="searchform"
						[data]="operatingSystems"
						name="hardwareArray"
						id="os-hardware"
						[educatorMode]="true"
						[checkeds]="checkHardware"
					></app-checkbox-group>
					</fieldset>

				</div>
			</div>
		</form>
	</ng-container>
</div>
