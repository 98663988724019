import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class EventService {
    static readonly LOCALSTORAGE_EDUCATOR_MODE_KEY = 'Infiniscope:EducatorMode';
	private messageSource = new BehaviorSubject({ action: '', dataob: {} });
	public currentData = this.messageSource.asObservable();
	public educatorMode: boolean = false;
	public searchQuery: string = '';

	constructor(@Inject(PLATFORM_ID) private platformId: any) {
		// if (isPlatformBrowser(platformId)) {
		// 	this.educatorMode = localStorage.getItem(EventService.LOCALSTORAGE_EDUCATOR_MODE_KEY) === 'true';
		// }
		this.educatorMode = true;
	}

	public SetEducatorMode(value: boolean) {
		value = true;
		if (isPlatformBrowser(this.platformId)) {
			localStorage.setItem(EventService.LOCALSTORAGE_EDUCATOR_MODE_KEY, value.toString());
		}
		this.educatorMode = value;
	}

	// Create event for sharing data between any components
	globalEvent(obj: any) {
		this.messageSource.next(obj);
	}
}
