import { isPlatformBrowser, Location, PopStateEvent } from "@angular/common";
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'infiniscope-website';

	static isBrowser = new BehaviorSubject<boolean>(null);

	constructor(
		@Inject(PLATFORM_ID) private platformId: any,
		private meta: Meta,
		private router: Router,
	) {
		AppComponent.isBrowser.next(isPlatformBrowser(platformId));
	}

	ngOnInit() {
		if(!environment.production) {
			this.meta.addTag({ name: 'robots', content: 'noindex' });
		}

		this.router.events.subscribe((ev:any) => {
			// console.log(ev);
			if (ev instanceof NavigationStart) window.scrollTo(0, 0);
		});
	}
}
